<template>
  <div class="background">
    <div id="app" @click="onClick">
      <div class="content">
        <h1 class="title">
          Los pilotos de <span class="highlight">Formula 1</span> reaccionan de media en <span class="highlight">0,2 segundos</span>.
        </h1>
        <h2 class="subtitle">
          SUPERA ESTE TIEMPO PARA ACCEDER AL <span class="drop">DROP #1</span>
        </h2>
        <button v-if="showButton" class="drop-button" @click="goToDropPage">DROP #1</button>
        
        <div id="lights-container">
          <div id="connector"></div>
          <LightStrip v-for="id in 5" :key="id" :ref="el => { if (el) lightRefs.push(el) }" />
        </div>

        <h2 class="time" :class="{ 'false-start': result === 'SALIDA EN FALSO!' }">{{ result !== null ? result : "" }}</h2>
        <div class="population-text" v-if="state === FINISHED">{{ populationMessage }}</div>
        
        <div class="help">
          <p><em>Haz clic o toca en cualquier parte de la pantalla para comenzar. Haz clic de nuevo cuando las luces se apaguen.</em></p>
        </div>

        <div class="record-container">
          <div class="record-text">Récord Personal: {{ bestTime === null ? "Haz un tiempo." : formatTime(bestTime) }}</div>
        </div>
      </div>
      
      <footer>
        <img src="/fastest-final-white.png" alt="Logo" class="logo">
      </footer>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, watch, computed } from 'vue';
import LightStrip from "./components/LightStrip.vue";
import confetti from 'canvas-confetti';

const IDLE = "idle";
const RUNNING = "running";
const WAITING = "waiting";
const FINISHED = "finished";
const LIGHT_ON_INTERVAL = 1100;

export default {
  components: { LightStrip },
  setup() {
    const state = ref(IDLE);
    const nextLightStrip = ref(0);
    const result = ref("00.000");
    const startTime = ref(null);
    const timerId = ref(null);
    const fuzzerId = ref(null);
    const time = ref(0);
    const bestTime = ref(parseFloat(localStorage.bestTime) || null);
    const lightRefs = ref([]);
    const showButton = ref(false);

    const clearLights = () => lightRefs.value.forEach(l => l.switchOn(false));

    const turnOnNextLight = () => {
      if (nextLightStrip.value == 5) {
        fuzzedLightsOut();
        clearInterval(timerId.value);
      } else {
        lightRefs.value[nextLightStrip.value].switchOn(true);
        nextLightStrip.value++;
      }
    };

    const fuzzedLightsOut = () => {
      const fuzzyInterval = Math.random() * 2700 + 3600;
      fuzzerId.value = setTimeout(() => {
        clearLights();
        startTime.value = Date.now();
        state.value = WAITING;
      }, fuzzyInterval);
    };

    const start = () => {
      nextLightStrip.value = 0;
      result.value = "00.000";
      startTime.value = null;
      clearLights();
      turnOnNextLight();
      timerId.value = setInterval(turnOnNextLight, LIGHT_ON_INTERVAL);
    };

    const formatTime = (ms) => {
      if (ms === null) return "Haz un tiempo.";
      const secs = (ms / 1000).toFixed(3);
      return `${(parseInt(secs) < 10 ? "0" : "") + secs}`;
    };

    const getPopulationPercentage = (time) => {
      const timeInSeconds = time / 1000;
      if (timeInSeconds <= 0.100) return 100;
      if (timeInSeconds <= 0.125) return 99.59;
      if (timeInSeconds <= 0.150) return 97.86;
      if (timeInSeconds <= 0.160) return 96.14;
      if (timeInSeconds <= 0.170) return 94.42;
      if (timeInSeconds <= 0.180) return 92.70;
      if (timeInSeconds <= 0.190) return 90.98;
      if (timeInSeconds <= 0.200) return 89.26;
      if (timeInSeconds <= 0.210) return 87.54;
      if (timeInSeconds <= 0.220) return 85.82;
      if (timeInSeconds <= 0.230) return 84.10;
      if (timeInSeconds <= 0.240) return 82.38;
      if (timeInSeconds <= 0.250) return 80.66;
      if (timeInSeconds <= 0.260) return 78.94;
      if (timeInSeconds <= 0.270) return 77.22;
      if (timeInSeconds <= 0.280) return 75.50;
      if (timeInSeconds <= 0.290) return 73.78;
      if (timeInSeconds <= 0.300) return 72.06;
      if (timeInSeconds <= 0.310) return 70.34;
      if (timeInSeconds <= 0.320) return 68.62;
      if (timeInSeconds <= 0.330) return 66.90;
      if (timeInSeconds <= 0.340) return 65.18;
      if (timeInSeconds <= 0.350) return 63.46;
      if (timeInSeconds <= 0.360) return 61.74;
      if (timeInSeconds <= 0.370) return 60.02;
      if (timeInSeconds <= 0.380) return 58.30;
      if (timeInSeconds <= 0.390) return 56.58;
      if (timeInSeconds <= 0.400) return 54.86;
      if (timeInSeconds <= 0.410) return 53.14;
      if (timeInSeconds <= 0.420) return 51.42;
      if (timeInSeconds <= 0.430) return 49.70;
      if (timeInSeconds <= 0.440) return 47.98;
      if (timeInSeconds <= 0.450) return 46.26;
      if (timeInSeconds <= 0.460) return 44.54;
      if (timeInSeconds <= 0.470) return 42.82;
      if (timeInSeconds <= 0.480) return 41.10;
      if (timeInSeconds <= 0.490) return 39.38;
      if (timeInSeconds <= 0.500) return 37.66;
      if (timeInSeconds <= 0.510) return 35.94;
      if (timeInSeconds <= 0.520) return 34.22;
      if (timeInSeconds <= 0.530) return 32.50;
      if (timeInSeconds <= 0.540) return 30.78;
      if (timeInSeconds <= 0.550) return 29.06;
      if (timeInSeconds <= 0.560) return 27.34;
      if (timeInSeconds <= 0.570) return 25.62;
      if (timeInSeconds <= 0.580) return 23.90;
      if (timeInSeconds <= 0.590) return 22.18;
      if (timeInSeconds <= 0.600) return 20.46;
      if (timeInSeconds <= 0.610) return 18.74;
      if (timeInSeconds <= 0.620) return 17.02;
      if (timeInSeconds <= 0.630) return 15.30;
      if (timeInSeconds <= 0.640) return 13.58;
      if (timeInSeconds <= 0.650) return 11.86;
      if (timeInSeconds <= 0.660) return 10.14;
      if (timeInSeconds <= 0.670) return 8.42;
      if (timeInSeconds <= 0.680) return 6.70;
      if (timeInSeconds <= 0.690) return 4.98;
      if (timeInSeconds <= 0.700) return 3.26;
      return 1;
    };

    const getMessage = (time) => {
      const timeInSeconds = time / 1000;
      if (timeInSeconds <= 0.100) return "¡A mí no me engañas, tú has hecho trampas!";
      if (timeInSeconds <= 0.200) return "¡Eres más rápido que el DRS de Verstappen!";
      if (timeInSeconds <= 0.300) return "¡Buen trabajo, estás en la pole position!";
      if (timeInSeconds <= 0.400) return "¡Nada mal, pero Bottas te está pisando los talones!";
      if (timeInSeconds <= 0.500) return "¡No está mal, pero puedes mejorar!";
      if (timeInSeconds <= 0.700) return "¡No está mal, pero no te duermas en el volante!";
      return "¡Te adelantó el safety car! ¿Qué pasó ahí?";
    };

    const populationMessage = computed(() => {
      if (state.value !== FINISHED) return "";
      const percentage = getPopulationPercentage(time.value);
      const message = getMessage(time.value);
      return `${message} Eres más rápido que el ${percentage.toFixed(2)}% de la población`;
    });

    const onClick = () => {
      if (state.value === RUNNING) {
        state.value = IDLE;
        result.value = "SALIDA EN FALSO!";
        clearInterval(timerId.value);
        clearTimeout(fuzzerId.value);
      } else if (state.value === IDLE || state.value === FINISHED) {
        state.value = RUNNING;
        start();
      } else if (state.value === WAITING) {
        state.value = FINISHED;
        const timeDiff = Date.now() - startTime.value;
        const slowedTimeDiff = timeDiff * 0.45;
        result.value = formatTime(slowedTimeDiff);
        time.value = slowedTimeDiff;
        if (slowedTimeDiff < bestTime.value) {
          bestTime.value = slowedTimeDiff;
          localStorage.bestTime = bestTime.value;
        }
        
        if (slowedTimeDiff < 400) {
          showButton.value = true;
        }
      }
    };

    const goToDropPage = () => {
      window.location.href = 'https://fastestbrand.com/';
    };

    onMounted(() => {
      lightRefs.value = lightRefs.value.filter(Boolean);
    });

    watch(() => state.value, (newState) => {
      if (newState === FINISHED) {
        if (time.value < 299) {
          showButton.value = true;
          confetti({
            particleCount: 100,
            spread: 70,
            origin: { y: 0.6 }
          });
        }
      }
    });

    return { state, result, time, bestTime, lightRefs, onClick, formatTime, showButton, goToDropPage, FINISHED, populationMessage };
  }
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Anton&family=Shrikhand&display=swap');

.background {
  background: linear-gradient(to right, #191b23, #272b37, #363a4a);
  background-size: 200% 100%;
  animation: gradientMove 10s ease infinite;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

@keyframes gradientMove {
  0% {background-position: 0% 50%;}
  50% {background-position: 100% 50%;}
  100% {background-position: 0% 50%;}
}

#app {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  color: #e0e0e0;
  font-family: 'Anton', sans-serif;
  padding: 20px;
  box-sizing: border-box;
}

.content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.title {
  font-size: clamp(1.2rem, 3vw, 2rem);
  color: #f0f0f0;
  text-align: center;
  margin-bottom: 1vh;
}

.subtitle {
  font-size: clamp(1.5rem, 4vw, 2.5rem);
  color: #f0f0f0;
  text-align: center;
  margin-bottom: 2vh;
}

.highlight {
  color: #ffae00;
}

.drop {
  font-family: 'Shrikhand', cursive;
  position: relative;
  display: inline-block;
}

#lights-container {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 2vh 0;
  padding: 10px;
  border-radius: 15px;
}

#connector {
  position: absolute;
  background: #333333;
  height: 3px;
  top: 50%;
  width: 90%;
  left: 5%;
  z-index: 0;
}

.time {
  font-size: clamp(2.5rem, 12vw, 7rem);
  font-weight: 300;
  line-height: 1.2;
  color: #f0f0f0;
  text-align: center;
  margin-bottom: 1vh;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.time.false-start {
  font-size: clamp(1.8rem, 7vw, 3.5rem);
}

.population-text {
  font-size: clamp(0.9rem, 2.5vw, 1.3rem);
  color: #ffae00;
  text-align: center;
  margin-bottom: 1vh;
}

.help {
  color: #c0c0c0;
  text-align: center;
  margin-bottom: 1vh;
  font-size: clamp(0.7rem, 2vw, 1rem);
}

.record-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.record-text {
  font-size: clamp(0.9rem, 2.5vw, 1.3rem);
  color: #c0c0c0;
  text-align: center;
  margin-bottom: 1vh;
}

.drop-button {
  font-family: 'Shrikhand', cursive;
  background-color: #4169E1;
  color: white;
  border: none;
  padding: 1vh 2vw;
  border-radius: 5px;
  font-size: clamp(0.9rem, 2.5vw, 1.3rem);
  cursor: pointer;
  transition: all 0.3s ease;
  animation: dropAnimation 0.5s ease-out;
  position: relative;
  z-index: 1;
  margin-bottom: 2vh;
}

.drop-button::before {
  content: '';
  position: absolute;
  top: -3px;
  left: -3px;
  right: -3px;
  bottom: -3px;
  background: inherit;
  filter: blur(8px);
  opacity: 0;
  z-index: -1;
  border-radius: 7px;
  transition: opacity 0.3s ease;
}

.drop-button:hover {
  transform: scale(1.1);
}

.drop-button:hover::before {
  opacity: 0.7;
  background: #87CEFA;
}

@keyframes dropAnimation {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

footer {
  display: flex;
  justify-content: center;
  padding: 1vh 0;
}

.logo {
  width: clamp(100px, 20vw, 200px);
}

@media only screen and (min-width: 1024px) {
  .content {
    max-width: 1200px;
    margin: 0 auto;
  }

  #lights-container {
    transform: scale(1.2);
  }
}
</style>