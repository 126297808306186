<template>
  <div class="light-container">
    <div
      v-for="light in lights"
      :key="light.id"
      class="light"
      :class="{ on: light.on, off: !light.on }"
    ></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      lights: [
        { id: 0, on: false },
        { id: 1, on: false },
        { id: 2, on: false },
        { id: 3, on: false },
      ],
    };
  },
  methods: {
    switchOn(on) {
      this.lights.forEach(light => light.on = on);
    },
  },
};
</script>

<style scoped>
.light-container {
  width: 80px;
  padding: 5px;
  border-radius: 10px;
  background: #2a2a2a;
  margin: 0 5px;
  display: flex;
  flex-direction: column;
}

.light {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background: #4a4a4a;
  margin: 5px 0;
  transition: all 0.3s ease;
}

.light.on {
  background: #ff0000;
  box-shadow: 0 0 15px #ff0000;
}

@media only screen and (max-width: 600px) {
  .light-container {
    width: 50px;
  }

  .light {
    width: 40px;
    height: 40px;
  }
}
</style>